.container-documentos {
  width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  border-radius: 10px;
  background: rgb(236, 236, 236);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  padding: 15px 15px;
}

.imagen-documento {
  width: 200px;
  height: 200px;
  object-fit: contain;
}