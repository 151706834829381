.container-principal-perfil-alumno {
  background-color: white;
}

.perfil-alumno__personal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contenedor-_foto-alumno {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal__fondo {
  width: 100%;
  background: #adf0a5;
  background: -webkit-linear-gradient(90deg, #adf0a5 0%, #009605 100%);
  background: linear-gradient(90deg, #adf0a5 0%, #009605 100%);
  border-radius: 48% 52% 48% 52% / 0% 0% 100% 100%;

  display: flex;
  justify-content: center;
  margin-bottom: 150px;
}

.perfil-foto-alumno {
  width: 250px;
  height: 250px;
  object-fit: contain;
  background-color: black;
  border-radius: 50%;
  border: 8px solid white;

  transform: translateY(50%);
}

.perfil-alumno__centro-idiomas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contenedor__presentacion-documentos {
  display: flex;
  justify-content: space-evenly;
  gap: 35px;
  flex-wrap: wrap;
}

@media (max-width: 900px) {
  .personal__fondo {
    margin-bottom: 100px;
  }

  .perfil-foto-alumno {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 700px) {
  .personal__fondo {
    margin-bottom: 100px;
  }

  .perfil-foto-alumno {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 600px) {
  .personal__fondo {
    margin-bottom: 100px;
    height: 100px;
  }

  .perfil-foto-alumno {
    width: 130px;
    height: 130px;

    transform: translateY(20%);
  }
}

@media (max-width: 400px) {
  .personal__fondo {
    margin-bottom: 60px;
    height: 70px;
  }

  .perfil-foto-alumno {
    width: 90px;
    height: 90px;

    transform: translateY(20%);
  }
}