.mostrar-contraseña {
  display: flex;
  align-items: center;
}

.icon-mostrar-contraseña {
  cursor: pointer;
}

.icon-mostrar {
  color: blue;
}

.icon-mostrar-contraseña, .texto-mostrar-contaseña {
  font-size: 25px;
}

.campo-oscuro .icon-mostrar-contraseña, .campo-oscuro .texto-mostrar-contraseña {
  color: white;
}