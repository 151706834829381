.agregar-alumnos__formulario {
  display: flex;
  justify-content: center;
}

.formulario {
  width: 50%;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 24px 100px;
  background-color: #c3f6c0;
}

.formulario__titulo {
  font-size: 32px;
  text-align: center;
  font-family: var(--family-ubuntu);
  margin: 0;
}

.formulario__subtitulo {
  font-weight: 400;
  font-size: 25px;
}

.agregar-idiomas__icon {
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 50px;

  width: max-content;
  display: flex;
  align-items: center;
}

.caja__idioma-agregado {
  padding: 20px 0 20px 20px;
  margin-bottom: 50px;
  border-left: 2px solid black;

  position: relative;
}

.caja__idioma-agregado::before {
  content: '';
  width: 50px;
  height: 2px;
  background-color: black;
  
  position: absolute;
  top: 0;
  left: 0;
}

.caja__idioma-agregado::after {
  content: '';
  width: 50px;
  height: 2px;
  background-color: black;
  
  position: absolute;
  bottom: 0;
  left: 0;
}

.idioma__icon-delete {
  color: red;
  font-size: 25px;
}