.informacion-personal__indicador {
  font-size: 30px;
  margin: 0;
  font-family: var(--family-fredoka);
  font-weight: 500;
}

.indicadores__chicos .informacion-personal__indicador {
  font-size: 22px;
  font-weight: 500;
}

.informacion-personal__respuesta {
  font-family: var(--family-poppins);
  font-weight: 400;
  font-size: 20px;
  margin-top: 5px;
}

.indicadores__chicos .informacion-personal__respuesta {
  font-size: 18px;
}

@media (max-width: 500px) {
  .informacion-personal__indicador {
    font-size: 25px;
  }

  .informacion-personal__respuesta {
    font-size: 15px;
  }
}