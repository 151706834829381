.lista-opciones {
  margin: 24px 0;
}

.lista-opciones label {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}

.lista-opciones select {
  width: 100%;
  background-color: #fff;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, .06);
  padding: 20px 24px;
  border: none;
  outline-color: #6278f7;
  font-size: 15px;
  font-family: var(--family-poppins);
  
  cursor: pointer;
}

.lista-opciones__verde-claro select {
  background-color: #c3f6c0;
}