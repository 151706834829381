:root {
  --family-fredoka: 'Fredoka', sans-serif;
  --family-poppins: 'Poppins', sans-serif;
  --family-montserrat: 'Montserrat', sans-serif;
  --family-ubuntu: 'Ubuntu', sans-serif;
}

/* Estilo Scroll */
.scroll-personalizado::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll-personalizado::-webkit-scrollbar:vertical {
  width:10px;
}

.scroll-personalizado::-webkit-scrollbar-button:increment,.scroll-personalizado::-webkit-scrollbar-button {
  display: none;
} 

.scroll-personalizado::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll-personalizado::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.scroll-personalizado::-webkit-scrollbar-track {
  border-radius: 10px;  
}

/* Estilos body */
.body__pantalla-negra {
  overflow: hidden;
}

/* Estilos colores */
.bg__verde-claro {
  background-color: #c0ffa7;
}

/* Estilos de los títulos */
.titulos-1 {
  display: inline;
  font-size: 50px;
  margin: 0;
  font-family: var(--family-ubuntu);
  border-bottom: 5px solid greenyellow;
}

.titulos-2 {
  text-align: center;
  font-size: 40px;
  font-family: var(--family-fredoka);
  margin: 20px 0;
}

.titulos-3 {
  text-align: center;
  font-size: 25px;
  font-family: var(--family-fredoka);
}

.titulos-4 {
  text-align: center;
  font-size: 20px;
  font-family: var(--family-fredoka);
  font-weight: 400;
  margin-top: 0;
}

.titulos__blanco {
  color: white;
}

.titulos__verde-oscuro {
  color: green;
}

.titulos__sin-margen {
  margin: 0;
}

.titulos__izquierda {
  text-align: left;
}

.text-center {
  text-align: center;
}

.balance {
  text-wrap: balance;
}

.underline {
  text-decoration: underline;
}

.style-inside {
  list-style: inside;
}

.text-red {
  color: #ff0000;
}

.text-white {
  color: white;
}

.decoration-none {
  text-decoration: none;
}

/* Estilos de los contenedores */
  .inline-block {
    display: inline-block;
  }

.h-1dvh {
  height: 100dvh;
}

.max-content {
  width: max-content;
}

.b-2px-black {
  border: 2px solid black;
}

.contenedor__titulos-1 {
  padding: 10px 0;
}

.center {
  display: flex;
  justify-content: center;
}

.contenedor__todo-final {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.contenedor__todo-principio {
  display: flex;
  gap: 8px;
}

.contenedor__todo-principio-centrado {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contenedor__entre {
  display: flex;
  justify-content: space-between;
}

.contenedor__centrado-separacion {
  display: flex;
  justify-content: space-evenly;
}

.contenedor__centro-separacion {
  display: flex;
  justify-content: space-around;
}

.contenedor__filas-centrado-vertical {
  display: flex;
  align-items: center;
}

.contenedor__columna-centro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contenedor__ambos-lados_centrado {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contenedor__wrap {
  display: flex;
  flex-wrap: wrap;
}

.contenedor__centrado-vertical {
  display: flex;
  align-items: center;
  gap: 5px;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.contenedor__margin-ambos {
  margin: 24px 0;
}

.contenedor__margin-top {
  margin-top: 24px;
}

.contenedor__padding-top {
  padding-top: 5px;
}

.contenedor__completo {
  width: 100%;
}

.contenedor__gap-15 {
  gap: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.gap-y__10 {
  row-gap: 10px;
}

.gap-y__25 {
  row-gap: 25px;
}

.gap-x__25 {
  column-gap: 25px;
}

.gap-x__10 {
  column-gap: 10px;
}

.gap-0 {
  gap: 0;
}

.pointer {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

/* Estilos padding */
.padd__20 {
  padding: 20px;
}

.padd-x__20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padd-top__20 {
  padding-top: 20px;
}

.padd-y__10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padd-y__20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padd-b__10 {
  padding-bottom: 10px;
}

/* Estilos elementos */
.element-loader {
  width: 22px;
  height: 22px;
  border: 3px solid white;
  border-bottom-color: #FF3D00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  } 

/* Estilos de la tabla */
.contenedor__tabla-scroll {
  overflow-y: scroll;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.tamaño-tabla__250 {
  height: 250px;
}

.tamaño-tabla_250-400 {
  min-height: 250px;
  max-height: 400px;
}

.tamaño-tabla__300 {
  height: 300px;
}

.tamaño-tabla__350 {
  height: 350px;
}

.tamaño-tabla__400 {
  height: 400px;
}

.contenedor__tabla-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.contenedor__tabla-scroll::-webkit-scrollbar:vertical {
  width:10px;
}

.contenedor__tabla-scroll::-webkit-scrollbar-button:increment,.contenedor__tabla-scroll::-webkit-scrollbar-button {
  display: none;
} 

.contenedor__tabla-scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.contenedor__tabla-scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.contenedor__tabla-scroll::-webkit-scrollbar-track {
  border-radius: 10px;  
}

.scroll {
  overflow-y: scroll;
}

.tabla {
  border-spacing: 0;
  margin: auto;
}

.tabla-cabecera__tabla-scroll {
  background-color: white;
  position: sticky;
  top: 0;
}

.tabla-cabecera tr th {
  border-bottom: 3px solid greenyellow;
  padding: 8px 15px;
  text-align: center;
}

.tabla-cuerpo {
  font-family: var(--family-poppins);
  text-align: center;
  font-size: 15px;
}

/* Estilos de los botones */
.boton__verde-oscuro {
  text-align: center;
  height: 45px;
  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  gap: 5px;
  background: none;
  background-color: #008f39;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-decoration: none;
  cursor: pointer;

  transition: .5s;

  font-family: 'Poppins';
  font-size: 15px;
}

.boton__blanco {
  text-align: center;
  height: 45px;
  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 8px;
  color: green;
  text-decoration: none;
  gap: 5px;
  border: 2px solid green;
  background-color: white;
  cursor: pointer;
  
  transition: .5s;

  font-family: 'Poppins';
  font-size: 15px;
}

.boton__disabled {
  text-align: center;
  height: 45px;
  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  gap: 5px;
  border: 2px solid green;
  background-color: gray;
  cursor: not-allowed;

  font-family: 'Poppins';
  font-size: 15px;
}

.boton__verde-oscuro:hover, .boton__blanco:hover {
  transform: translateY(-10px);
}

.boton__azul {
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(to bottom right, #00c6ff, #0072ff);
  border: none;
  border-radius: 40px;
  box-shadow: 0px 4px 0px #0072ff;
  transition: all 0.2s ease-in-out;
}

.boton__azul:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 0px #0072ff;
}

.boton__azul:active {
  transform: translateY(0px);
  box-shadow: none;
  background-image: linear-gradient(to bottom right, #0072ff, #00c6ff);
}

.boton__azul:before,
.boton__azul:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

/* Estilos Icons */
.icon-80 {
  font-size: 80px;
  cursor: pointer;
}

.icon-40 {
  font-size: 40px;
  cursor: pointer;
}

.flecha-regresar {
  color: black;
}

.flecha-regresar__blanco {
  color: white;
}

/* Estilos modal */
/* .modal__medio {
  z-index: 5000 !important;
}

.modal__superior {
  z-index: 10000 !important;
} */

.container-modal__fondo-oscuro {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  z-index: 9999999999;
}

.modal {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__icon-salir {
  color: red;
  font-size: 80px;
  position: absolute;
  z-index: 200;
  right: 0;
  top: 0;
  cursor: pointer;
}

.advertencia__eliminar-alumno {
  box-sizing: border-box;
  width: 500px;
  height: 300px;
  border-radius: 5px;
  background-color: white;
  padding: 10px 20px;
}

.advertencia__editar-colores {
  box-sizing: border-box;
  width: 700px;
  height: 500px;
  border-radius: 5px;
  background-color: white;
  padding: 10px 20px;
}

.advertencia__titulo {
  text-align: center;
  color: red;
  font-size: 35px;
  margin: 0;
}

.advertencia__texto {
  text-align: center;
  font-size: 25px;
}

.advertencia__resaltar {
  font-weight: bold;
}

.modal__por-defecto {
  background-color: white;
  border-radius: 10px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow-y: auto;
}

.modal__contenido {
  width: clamp(200px, 70%, 500px);
  height: 400px;
  padding: 25px 25px;
}

.modal__contenido-2 {
  width: clamp(200px, 70%, 800px);
  height: 90%;
  padding: 25px 25px;
}

.modal__contenido-3 {
  width: clamp(200px, 70%, 500px);
  min-height: 400px;
  padding: 25px 25px;
}

.centrar__contenido {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__sombra {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

/* Estilos Calendar */
.fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
  text-wrap: wrap;
}

@media (max-width: 800px) {
  .tabla-cabecera tr th {
    padding: 8px 10px;
    text-align: center;
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .contenedor__titulos-1 {
    text-align: center;
  }

  .titulos-1 {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .titulo-justificantes {
    font-size: 25px;
  }

  .titulos-2 {
    font-size: 30px;
  }
}