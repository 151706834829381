.tabla-justificantes__titulo {
  text-align: center;
  font-size: 40px;
  font-family: 'Fredoka', sans-serif;
  margin: 20px 0;
}

.container-boton__justificantes {
  display: flex;
  justify-content: flex-end;
}

.container-foto-prueba {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

.caja-foto-prueba {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.foto-prueba {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.lcOxyP {
  min-width: 30% !important;
  max-width: 65% !important;
  max-height: 80%;
}

.foto-prueba__icon {
  color: red;
  font-size: 80px;
  position: absolute;
  z-index: 200;
  right: 0;
  top: 0;
  cursor: pointer;
}

.icon-aceptar__justificante {
  color: greenyellow;
}

.icon-rechazar__justificante {
  color: red;
  font-size: 45px;
}