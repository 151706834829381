.container-botones-usuario-justificantes {
  display: flex;
}

.link-usuario-justificantes {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: calc(100% / 3);
  text-wrap: balance;
  text-decoration: none;
  color: black;
  background-color: greenyellow;
  font-family: 'Fredoka', sans-serif;
  padding: 11px 8px;
} 

.link-usuario-justificante_activo, .link-usuario-justificantes:hover {
  background-color: green;
  color: white;
}

.link-usuario-justificantes span {
  text-align: center;
}

@media (max-width: 500px) {
  .container-botones-usuario-justificantes {
    flex-direction: column;
    gap: 5px;
    margin-top: 8px;
  }

  .link-usuario-justificantes {
    width: 100%;
    padding: 8px 0;
  }
}