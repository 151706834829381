

/* Central */
.container-panel-control__central {
  box-sizing: border-box;
  height: 100vh;
  transform: translateX(120px);
  width: calc(100% - 120px);
  background-color: white;
}

.central-caja-superior {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 15px;
  background-color: #c0ffa7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.central-caja-superior__empresa {
  height: 30px;
  display: flex;
  align-items: center;
}

.empresa-logo {
  height: 30px;
}

.empresa-titulo {
  font-size: 25px;
  font-family: 'Playfair Display', serif;
}

.central-caja-superior__cerrar-sesion {
  display: flex;
  align-items: center;
}

.cerrar-sesion {
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.logo-cerrar-sesion {
  margin-right: 5px;
}

.central-contenido {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 65px);
  padding: 0 20px 50px;
  overflow-y: auto;
}

@media (max-width: 800px) {
  .container-panel-control__central {
    width: calc(100% - 80px);
    transform: translateX(80px);
  }
}

@media (max-width: 700px) {
  .container-panel-control__central {
    width: 100%;
    transform: translateX(0px);
  }

  .central-contenido {
    padding: 0 20px 120px;
  }
}