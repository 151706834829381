.fila {
  cursor: pointer;
}

.td-admin {
  border-bottom: 1px solid black;
  padding: 16px;
}

.td-admin ul {
  margin: 0;
}

.ultima-td-admin {
  display: flex;
  align-items: center;
}

.td-mobil {
  display: none;
}

.elemento-par {
  background-color: white;
}

.cerca-pago {
  background-color: #ff8000;
}

.dia-pago {
  background-color: #d28bfb;
}

.deudas {
  background-color: black;
  color: white;
}

.activo {
  background-color: #133210 !important;
  color: white !important;
}

.icon-justificante {
  cursor: pointer;
}

.icon-aceptar {
  color: greenyellow;
  font-size: 45px;
}

.icon-rechazar {
  color: red;
  font-size: 55px;
}

@media (max-width: 800px) {
  .td-admin {
    font-size: 10px;
    padding: 5px 0;
  }
}

@media (max-width: 600px) {
  .td-desktop {
    display: none;
  }

  .td-mobil {
    display: table-cell;
  }

  .icon-rechazar {
    font-size: 35px;
  }
}