
/* Aside */
.container-panel-control__aside {
  width: 120px;
  height: 100vh;
  padding: 20px 0;
  background-color: #133210;

  position: fixed;
  z-index: 5001;

  transition: .5s;
  overflow: hidden;
}

.container-panel-control__navbar {
  display: none;
}

.aside-activo {
  width: 300px !important;
}

.perfil-admin {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 25px;
  border-bottom: 3px solid white;
  font-size: 12px;
}

.foto-perfil-admin {
  transition: .5s;
  width: 50px;
  height: 50px;
  object-fit: contain;
  background-color: black;
  border-radius: 90%;
  border: 3px solid white;
}

.aside-activo .foto-perfil-admin {
  transition: .5s;
  width: 70px;
  height: 70px;
}

.info-admin {
  margin: 0;
  opacity: 0;
  font-family: var(--family-poppins);

}

.aside-activo .info-admin {
  transition: .5s;
  opacity: 1;
}

.perfil-admin__nombre {
  margin-top: 5px;
  font-weight: 400;
}

.perfil-admin__apellido {
  margin-top: 5px;
}

.perfil-admin__puesto {
  margin-bottom: 15px;
}

.panel-control__enlaces {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

.enlaces {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 0 8px 52px;
  color: white;
  text-decoration: none;
}

.enlaces:hover {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.29);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.9px);
}

.enlaces-activos {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.29);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.9px);
}

.enlaces div {
  display: inline-flex;
  align-items: center;
  gap: 7px;
}

.enlaces span {
  font-size: 15px;
  margin-left: 50px;
}

.aside-activo .enlaces span {
  display: inline;
  transition: .5s;
}

@media (max-width: 800px) {
  .container-panel-control__aside {
    width: 80px;
  }

  .enlaces {
    padding: 15px 0 15px 32px;
  }
}

@media (max-width: 700px) {
  .container-panel-control__aside {
    display: none;
  }

  .container-panel-control__navbar {
    display: block;
    position: fixed;
    bottom: 0;
    background-color: #133210;
    width: 100%;
    height: 55px;
    z-index: 1;

    display: flex;
  }

  .enlaces-moviles {
    height: 100%;
    flex-grow: 1;
    color: white;

    text-decoration: none;
  }

  .enlaces-moviles-activos {
    color: #c0ffa7;
    position: relative;
  }

  .enlaces-moviles-activos::before {
    content: '';
    width: 100%;
    height: 5px;
    border-radius: 14px;
    position: absolute;
    top: 0;
    background-color: #c0ffa7;
  }

  .enlaces-moviles div {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .enlaces-moviles div span {
    display: none;
  }

  .enlaces-moviles div img {
    width: 16px;
    height: 16px;
    object-fit: contain;

    border-radius: 100%;
    border: 1px solid white;
  }
}