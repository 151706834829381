/* Variables */
:root {
  --fs-titulos: 42px;
  --fs-subtitulos: 28px;
  --fs-texto: 18px;
}

/* Estilos Generales */
.cajas__promocionales {
  padding: 0 40px;

  margin-top: 80px;
}

.titulos__promocionales {
  position: relative;
  color: #062603;
  font-size: var(--fs-titulos);
  font-weight: 500;
  text-align: center;
  text-wrap: balance;
}

.subtitulos__promocionales {
  font-family: "Quicksand", Tofu;
  font-weight: 500;
  font-size: var(--fs-subtitulos);
  color: #5D1FF2;
  text-align: center;
}

.titulos__promocionales::after {
  content: '';
  width: 100%;
  height: 4px;
  background-color: green;
  border-radius: 5px;

  position: absolute;
  left: 0;
  bottom: -8px;
}

.promocional {
  overflow: hidden;
}

/* Contenedor Main */
.contenedor-promocional__contenido {
  margin-top: 94px;
}

/* Quienes Somos */
.caja-quienes-somos {
  display: flex;
}

.quienes-somos__imagen {
  width: 50%;
  height: 500px;

  background: rgb(236, 236, 236);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  border-radius: 20px;
  background-image: url(../img/quienes-somos_imagen.jpeg);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.quienes-somos__informacion {
  box-sizing: border-box;
  width: 50%;
  padding-left: 30px;
}

.quienes-somos__texto {
  font-size: var(--fs-texto);
  font-family: var(--family-poppins);
}

/* Footer */
.contenedor-footer {
  box-sizing: border-box;
  border-top: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
}

.contenedor-footer__informacion {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contenedor-footer__informacion h3 {
  font-size: 40px;
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  margin: 0;
}

.contenedor-footer__informacion img {
  width: 80px;
}

.contenedor-footer__derechos-autor {
  font-size: 20px;
  text-align: center;
}

/* Boton de Whatsapp */
.boton__whatsapp {
  position:fixed;
  bottom:40px;
  right:40px;
}

.whatsapp {
  height:80px;
  width:80px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:50px;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index:100;
}

.whatsapp__onda {
  position: absolute;
  top: 0;
  z-index: -1;

  animation: ondas 1.5s infinite;
}

.boton__whatsapp:hover .whatsapp__onda {
  animation: ondas .5s infinite;
}

@keyframes ondas {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

@media (min-width: 940px) and (max-width: 1200px) {
  /* Variables */
  :root {
    --fs-titulos: 36px;
    --fs-subtitulos: 24px;
    --fs-texto: 18px;
  }

  /* Footer */
  .contenedor-footer__informacion h3 {
    font-size: 35px;
  }

  .contenedor-footer__informacion img {
    width: 70px;
  }

  /* whatsapp */
  .whatsapp {
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 900px) and (max-width: 940px) {
  /* Variables */
  :root {
    --fs-titulos: 36px;
    --fs-subtitulos: 24px;
    --fs-texto: 18px;
  }

  /* Footer */
  .contenedor-footer__informacion h3 {
    font-size: 35px;
  }

  .contenedor-footer__informacion img {
    width: 70px;
  }

  /* whatsapp */
  .whatsapp {
    width: 60px;
    height: 60px;
  }

  .fa {
    font-size: 40px;
  }
}

@media (max-width: 900px) {
  /* Variables */
  :root {
    --fs-titulos: 36px;
    --fs-subtitulos: 24px;
    --fs-texto: 18px;
  }

  /* Footer */
  .contenedor-footer__informacion h3 {
    font-size: 35px;
  }

  .contenedor-footer__informacion img {
    width: 70px;
  }

  /* whatsapp */
  .whatsapp {
    width: 60px;
    height: 60px;
  }

  .fa {
    font-size: 40px;
  }
}

@media (max-width: 800px) {
  /* Quienes Somos */
  .caja-quienes-somos {
    flex-direction: column-reverse;
    align-items: center;
  }

  .quienes-somos__informacion {
    width: 100%;
    padding-left: 0;
  }

  .quienes-somos__imagen {
    width: 70%;
    height: 300px;
    background-attachment: unset;
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .contenedor-promocional__contenido {
    margin-top: 64px;
  }
}

@media (max-width: 500px) {
  /* Footer */
  .contenedor-footer__informacion img {
    width: 45px;
  }

  .contenedor-footer__informacion h3 {
    font-size: 25px;
  }
}

@media (max-width: 350px) {
  /* Variables */
  :root {
    --fs-titulos: 28px;
    --fs-subtitulos: 20px;
    --fs-texto: 15px;
  }
}