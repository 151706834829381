/* Columnas */
.tr-mobil {
  display: none;
}

/* aciones alumnos */
.icon-alumno {
  font-size: 35px;
  cursor: pointer;
}

.alumno-delete {
  color: red;
}

.alumno-readd {
  color: green;
}

.alumno-edit {
  color: blue;
}

@media (max-width: 600px) {
  .tr-mobil {
    display: table-cell;
  }

  .tr-desktop {
    display: none;
  }
}