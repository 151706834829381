.contenedor-usuario__contenido {
  width: calc(100% - 120px);
  height: 100vh;
  transform: translateX(120px);
}

.usuario__contenido {
  transform: translateY(55px);
  height: calc(100vh - 55px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 100px;
}

.container-principal-usuario {
  height: 100%;
}

.principal-usuario__botones {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usuario__logo {
  width: 40px;
}

.usuario__logo-texto {
  font-size: 30px;
  font-family: 'Playfair Display', serif;
}

.navbar-usuario {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);

  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.list-links {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
}

.link-usuario {
  position: relative;
  text-decoration: none;
  font-size: 30px;
  color: white;
  transition: .8s;
}

.link-usuario::after {
  content: '';
  position: absolute;
  display: block;
  width: 0%;
  height: 2px;
  right: 0;
  transition: .8s;
  background-color: greenyellow;
}

.link-usuario:hover {
  color: greenyellow;
  transition: .8s;
}

.link-usuario:hover::after {
  content: '';
  width: 100%;
  height: 2px;
  left: 0;
  transition: .8s;
}

.link-usuario__activo {
  color: greenyellow;
}

.link-usuario__activo::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  right: 0;
  background-color: greenyellow;
}

@media (max-width: 800px) {
  .contenedor-usuario__contenido {
    width: calc(100% - 80px);
    transform: translateX(80px);
  }

  .usuario__logo {
    width: 35px;
  }
  
  .usuario__logo-texto {
    font-size: 25px;
  }

  .logo-cerrar-sesion {
    font-size: 35px;
    color: red;
  }

  .texto-cerrar-sesion {
    display: none;
  }
}

@media (max-width: 700px) {
  .contenedor-usuario__contenido {
    width: 100%;
    transform: translateX(0px);
  }
}

/* @media (max-width: 650px) {
  .usuario__logo-texto {
    
  }
} */