.page_404 { 
  box-sizing: border-box;
  height: 100vh;
  background:#fff;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.page_404-contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200px;
}

.page_404-logo {
  width: 255px;
}

.contant_box_404 { 
  margin-top:0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contant_box_404 h3 {
  font-size: 50px;
  margin: 0;
}

.contant_box_404 p {
  font-size: 25px;
  text-align: center;
}

@media (max-width: 750px) {
  .contant_box_404 p {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .page_404-contenedor {
    font-size: 100px;
  }
  
  .page_404-logo {
    width: 110px;
  }
}