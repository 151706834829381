.container-principal-scanner-alumno {
  min-height: 100vh;
}

.scanner-alumno-fondo {
  box-sizing: border-box;
  background: none;
  background-color: green;
  margin: 0;
  padding: 8px;
  height: auto;
}

.perfil-foto-scanner-alumno {
  box-sizing: border-box;
  width: 250px;
  height: 250px;
  padding: 5px;
  transform: none;
}

.bienvenida__titulo {
  text-align: center;
  margin: 8px 0 0 0;
  font-size: 38px;
  font-family: 'Ubuntu', sans-serif;
}

.container__hora-entrada {
  display: flex;
  justify-content: center;
}

.container__hora-entrada .hora-entrada__texto {
  font-size: 25px;
}

.puntual {
  color: green;
}

.impuntual {
  color: red;
}

.container-perfil-alumno__informacion {
  display: flex;
}

.perfil-scaner-alumno__personal, .perfil-scaner-alumno__centro-idiomas {
  width: 50%;
}

.container-loading-bar {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px 0;
}

.caja-loading-bar {
  position: relative;
  width: 110px;
  height: 110px;
}

.loading-bar {
  position: absolute;
  width: 110px;
  height: 110px;
  background: transparent;
  border: px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 111px;
  font-family: sans-serif;
  font-size: 40px;
  color: #133210;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-shadow: 0 0 20px #133210;
  box-shadow: 0 0 20px rgba(0,0,0,.5);
}

.loading-bar:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 5px solid #133210;
  border-right: 5px solid #133210;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

.comprobante-mensualidad {
  padding: 22px;
  font-size: 18px;
  border-radius: 14px;
}

/* Alumno no encontrado */
.texto__alumno-no-encontrado {
  box-sizing: border-box;
  padding: 0 20px;
  text-align: center;
  font-size: 50px;
  font-weight: 600;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

@media (max-width: 850px) {
  .container-perfil-alumno__informacion {
    flex-direction: column;
  }

  .perfil-scaner-alumno__personal, .perfil-scaner-alumno__centro-idiomas {
    width: 100%;
  }

  .icono__alumno-no-encontrado {
    width: 340px;
  }

  .texto__alumno-no-encontrado {
    font-size: 35px;
  }
}

@media (max-width: 515px) {
  .bienvenida__titulo {
    font-size: 32px;
  }

  .icono__alumno-no-encontrado {
    width: 250px;
  }

  .texto__alumno-no-encontrado {
    font-size: 25px;
  }
}