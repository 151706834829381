.graficas__campos {
  display: flex;
}

.campos-grafica {
  box-sizing: border-box;
  width: 50%;
  padding: 0 10px;
}

@media (max-width: 600px) {
  .graficas__campos {
    display: block;
  }

  .campos-grafica  {
    width: 100%;
  }
}