.login-box {
  width: 100%;
  height: calc(100% - 35px);
  padding: 8px 40px;
  background: rgba(0,0,0,.9);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  font-family: 'Fredoka', sans-serif;
  color: white;
}

.login-box__titulo {
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.login-box__subtitulo {
  color: white;
  text-align: center;
  font-size: 25px;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #fff;
  font-size: 12px;
}

.caja-enlace-scanner {
  margin-top: 25px;
}

.cajas-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.span-qr {
  font-size: 20px;
}

.scanner {
  color: white;
  cursor: pointer;
  font-size: 70px;
}

@media (max-width: 420px) {
  .login-box__titulo {
    font-size: 1.2rem;
  }  
}