/* Diseño N.1 de la tabla */
.contenedor-tabla__1 {
  display: flex;
  justify-content: center;
  min-height: 10px;
}

.contenedor-tabla__1 .tabla {
  border-spacing: 0;
}

.contenedor-tabla__1 .tabla__cabecera tr th {
  border-bottom: 3px solid red;
  padding: 8px 15px;
  text-align: center;
}

.contenedor-tabla__1 .tabla__cuerpo {
  text-align: center;
}

/* Diseño N.2 de la tabla */
.contenedor-tabla__2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}