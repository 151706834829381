.foto-perfil__titulo {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.foto-perfil__caja-alumno {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.foto-perfil__alumno {
  width: 150px;
  height: 150px;
  border: 3px solid black;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
}

.foto-alumno {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.imagen {
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: -1;
}

.foto-explicacion .foto-perfil__alumno {
  border-radius: 0;
  width: 300px;
  height: 300px;
}

.foto-explicacion .foto-alumno {
  border-radius: 0;
}

.foto-cuadrada .foto-perfil__alumno {
  border-radius: 0;
  width: 300px;
  height: 300px;
}

.foto-cuadrada .foto-alumno {
  border-radius: 0;
}