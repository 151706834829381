/* Diseño por defecto */
.campo {
  margin: 24px 0;
}

.campo label, .campoMUI label {
  font-family: var(--family-montserrat);
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}

.campo input {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, .06);
  border: none;
  padding: 20px 20px;
  outline-color: #6278f7;
  font-size: 15px;
  font-family: var(--family-poppins);
}

.campo-color input {
  height: 40px;
  padding: 5px 20px;
}

/* Diseño Oscuro */
.campo-oscuro label {
  color: white;
}

.campo-oscuro input {
  background-color: black;
  outline-color: greenyellow;
  color: white;
}

/* Diseño Verde claro */
.campo-verde-claro label {
  color: black;
}

.campo-verde-claro input, .campo-verde-claro textarea {
  background-color: #c3f6c0;
  color: black;
}

/* Diseño disabled */
.campo-disabled input {
  background-color: rgb(185, 182, 182);
  cursor: not-allowed;
}